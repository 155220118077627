import { useListAndDetail } from '@/utils/mobiles/useListAndDetail';
import { Modal, ModalProps } from '@chakra-ui/react';
import { useEffect } from 'react';

type ClosableModalProps = ModalProps & {
  isChildModalOpen?: boolean;
};

const ClosableModal = (props: ClosableModalProps) => {
  const { hash } = window.location;
  const { setUrlHash } = useListAndDetail();

  const { isChildModalOpen, ...modalProps } = props;
  const { isOpen, onClose } = modalProps;

  useEffect(() => {
    const handlePopstate = () => {
      if (isOpen) {
        if (!isChildModalOpen) {
          onClose();
        }

        const newHash = window.location.hash;
        if (hash !== newHash) {
          setUrlHash(hash);
        }
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [hash, isOpen, onClose, setUrlHash, isChildModalOpen]);
  return <Modal {...modalProps} />;
};

export default ClosableModal;
